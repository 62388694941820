import React, { useState } from 'react';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import { green } from '@mui/material/colors';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import MembershipHistory from '../Account/MembershipHistory';
import { Orders } from '../Account/Orders';
import { Addresses } from '../Account/Directions';
import ChangePasswordDialog from '../Account/ChangePasswordDialog';

const AccountContent = ({
  selectedTab,
  clientData,
  handleChange,
  setClientData,
  handleUpdate,
  isMobile,
  orders,
  directions,
  memberships,
}) => {

    const [openChangePassword, setOpenChangePassword] = useState(false);

  const handleOpenChangePassword = () => setOpenChangePassword(true);
  const handleCloseChangePassword = () => setOpenChangePassword(false);

  const handleSavePassword = (newPassword) => {
    handleCloseChangePassword();
  };

  switch (selectedTab) {
    case 'personalData':
      return (
        <Box
          sx={{
            width: '100%',
            maxWidth: isMobile ? '100%' : 800,
            mx: 'auto',
            p: isMobile ? 1 : 2,
            bgcolor: 'background.paper',
            borderRadius: 1,
            boxShadow: 1,
          }}
        >
          <Typography variant="h6" align="left" sx={{ mb: 2 }}>
            Mis Datos
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="RUT"
                variant="outlined"
                name="rut"
                value={clientData.rut}
                InputProps={{
                  readOnly: true,
                }}
                disabled
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': { borderColor: green[500] },
                    '&:hover fieldset': { borderColor: green[700] },
                    '&.Mui-focused fieldset': { borderColor: green[500] },
                  },
                  '& .MuiInputBase-root': { width: '100%' },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Nombre"
                variant="outlined"
                name="name"
                value={clientData.name}
                onChange={handleChange}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': { borderColor: green[500] },
                    '&:hover fieldset': { borderColor: green[700] },
                    '&.Mui-focused fieldset': { borderColor: green[500] },
                  },
                  '& .MuiInputBase-root': { width: '100%' },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Apellido"
                variant="outlined"
                name="last_name"
                value={clientData.last_name}
                onChange={handleChange}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': { borderColor: green[500] },
                    '&:hover fieldset': { borderColor: green[700] },
                    '&.Mui-focused fieldset': { borderColor: green[500] },
                  },
                  '& .MuiInputBase-root': { width: '100%' },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Email"
                variant="outlined"
                name="email"
                value={clientData.email}
                onChange={handleChange}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': { borderColor: green[500] },
                    '&:hover fieldset': { borderColor: green[700] },
                    '&.Mui-focused fieldset': { borderColor: green[500] },
                  },
                  '& .MuiInputBase-root': { width: '100%' },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Teléfono"
                variant="outlined"
                name="phone"
                value={clientData.phone}
                onChange={handleChange}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': { borderColor: green[500] },
                    '&:hover fieldset': { borderColor: green[700] },
                    '&.Mui-focused fieldset': { borderColor: green[500] },
                  },
                  '& .MuiInputBase-root': { width: '100%' },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Fecha de Nacimiento"
                  value={clientData.date_of_birthday}
                  onChange={(newValue) => setClientData({ ...clientData, date_of_birthday: newValue })}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      variant="outlined"
                      {...params}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': { borderColor: green[500] },
                          '&:hover fieldset': { borderColor: green[700] },
                          '&.Mui-focused fieldset': { borderColor: green[500] },
                        },
                        '& .MuiInputBase-root': { width: '100%' },
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="center" gap={2} mt={4}>
            <Button variant="contained" sx={{ backgroundColor: 'green', color: 'white' }} onClick={handleUpdate}>
              Guardar cambios
            </Button>
            <Button
              variant="outlined"
              sx={{ borderColor: 'green', color: 'green' }}
              onClick={handleOpenChangePassword}
            >
              Cambiar contraseña
            </Button>
          </Box>
          <ChangePasswordDialog
            open={openChangePassword}
            onClose={handleCloseChangePassword}
            onSave={handleSavePassword}
          />
        </Box>
      );
    case 'orders':
      return <Orders orders={orders} />;
    case 'addresses':
      return <Addresses addresses={directions} />;
    case 'membershipBalance':
      return <MembershipHistory memberships={memberships} />;
    default:
      return null;
  }
};

export default AccountContent;
