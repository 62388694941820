import React, { useEffect, useState, useContext } from 'react';
import { Box, Typography, Card, CardContent, CardMedia, Grid, Divider, Button } from '@mui/material';
import { useNavigate, useSearchParams } from "react-router-dom";
import './SalesSuccessPage.scss'; 
import { CartContext } from "../../context/CartContext";
import { commitTransactionSales } from "../../client/apiWebpayService";

export const SalesSuccessPage = () => {
  const [searchParams] = useSearchParams();
  const idOrder = searchParams.get('id');
  const [loading, setLoading] = useState(true);
  const [orderDetails, setOrderDetails] = useState(null);
  const { setCartItems, clearCart } = useContext(CartContext);
  const navigate = useNavigate();

  useEffect(() => {
    const token = new URLSearchParams(window.location.search).get('token_ws');

    // Verificar si el token ya ha sido procesado
    const processedToken = localStorage.getItem('processedToken');
    if (!token || processedToken === token) {
      // Redirigir a la página principal si el token ya fue procesado o no existe
      navigate('/');
      return;
    }

    const confirmPayment = async () => {
      try {
        const sales = localStorage.getItem("cartData");

        // Llamar a commitTransactionSales para obtener los detalles
        //const orderId = localStorage.getItem('orderId');
        console.log('id', idOrder)
        const response = await commitTransactionSales(token, sales, idOrder);
        const responseData = response.data;

        // Guardar detalles de la orden desde la respuesta
        setOrderDetails(responseData);

        // Limpiar el carrito después de la compra
        setCartItems([]);
        
        // Guardar el token como procesado en localStorage
        localStorage.setItem('processedToken', token);
        //localStorage.removeItem("cartData");
        localStorage.removeItem("balance");
        localStorage.removeItem("coupon");
        localStorage.removeItem("cartItems");
        clearCart();

        // Quitar el token de la URL
        window.history.replaceState({}, document.title, window.location.pathname);
      } catch (error) {
        console.error("Error al confirmar el pago:", error);
      } finally {
        setLoading(false); 
      }
    };

    confirmPayment();
  }, [setCartItems, navigate]);

  const savedCartData = JSON.parse(localStorage.getItem('cartData'));

  if (loading) {
    return (
      <div className="loader">
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
      </div>
    );
  }

  const handleBack = () =>{
    window.location.href = '/';
    localStorage.removeItem("balance");
    localStorage.removeItem("cartData");
    localStorage.removeItem("coupon");
    localStorage.removeItem("orderId");
  }

  return (
    <Box className="sales-success-page" sx={{ padding: 4 }}>
      <Typography variant="h4" gutterBottom>Pago Exitoso</Typography>
      <Typography variant="body1" gutterBottom>Gracias por tu compra. Tu pedido ha sido procesado.</Typography>

      {orderDetails && (
        <Grid container spacing={4} mt={4}>
          <Grid item xs={12} md={8}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6" gutterBottom>Productos</Typography>
                {savedCartData?.items.map((item, index) => (
                  <Card key={index} variant="outlined" sx={{ marginBottom: 2 }}>
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={3}>
                          <CardMedia
                            component="img"
                            height="100"
                            image={item.image}
                            alt={item.name}
                            sx={{ borderRadius: 2 }}
                          />
                        </Grid>
                        <Grid item xs={9}>
                          <Typography variant="body1" fontWeight="bold">{item.name}</Typography>
                          <Typography variant="body2">Cantidad: {item.quantity}</Typography>
                          <Typography variant="body2">Precio: ${item.price.toLocaleString('es-CL')}</Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                ))}
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={4}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6" gutterBottom>Detalles de Envío</Typography>
                <Typography variant="body2">Dirección: {orderDetails?.destination?.address}, {orderDetails?.destination?.city?.name}</Typography>
                <Typography variant="body2">Fecha de Entrega: {orderDetails?.delivery_window_init}</Typography>

                <Divider sx={{ marginY: 2 }} />

                <Typography variant="h6" gutterBottom>Resumen del Pedido</Typography>
                <Typography variant="body2">Total de la Compra: ${orderDetails?.total_amount?.toLocaleString('es-CL')}</Typography>
                <Typography variant="body2">Valor de Despacho: ${orderDetails?.shipping_amount?.toLocaleString('es-CL')}</Typography>
                <Typography variant="body2">Descuento: ${orderDetails?.discount?.toLocaleString('es-CL')}</Typography>
                <Typography variant="body2">Ahorro por Membresía: ${orderDetails?.membership_savings?.toLocaleString('es-CL')}</Typography>
                <Typography variant="body2">Monto Final: ${orderDetails?.final_amount?.toLocaleString('es-CL')}</Typography>
              </CardContent>
            </Card>

            <Button variant="contained" color="primary" fullWidth sx={{ marginTop: 2 }} onClick={() => handleBack()}>
              Volver al Inicio
            </Button>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default SalesSuccessPage;
