import React, { useState } from "react";
import { Container, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Accordion, AccordionSummary, AccordionDetails, Typography, Box } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import StarIcon from "@mui/icons-material/Star";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import EditIcon from "@mui/icons-material/Edit";
import BusinessIcon from "@mui/icons-material/Business";
import PaymentIcon from "@mui/icons-material/Payment";
import ContactMailIcon from "@mui/icons-material/ContactMail";

const categories = [
  { name: "TODO SOBRE T’AKAY", icon: <HelpOutlineIcon /> },
  { name: "TIPOS DE PRODUCTOS, MEMBRESÍA Y PEDIDOS", icon: <StarIcon /> },
  { name: "Despacho y coberturas", icon: <LocalShippingIcon /> },
  { name: "Dudas del pedido", icon: <ShoppingCartIcon /> },
  { name: "Problemas con el pedido", icon: <ReportProblemIcon /> },
  { name: "Modificar datos", icon: <EditIcon /> },
  { name: "Empresas", icon: <BusinessIcon /> },
  { name: "Medios de pago", icon: <PaymentIcon /> },
  { name: "Contáctanos", icon: <ContactMailIcon /> }
];

const questionsData = {
  "TODO SOBRE T’AKAY": [
    { question: "¿Cómo me registro en T’AKAY?", answer: "Para registrarte, debes dirigirte a la página principal de www.takayapp.cl y eleccionar el botón de color verde “REGISTRARSE”, completar tus datos, aceptar los términos y condiciones y crear la cuenta. Esta cuenta solo puede ser creada y gestionada por un mayor de edad." },
    { question: "¿Cómo inicio sesión?", answer: "Para iniciar sesión, debes dirigirte a la esquina superior derecha y seleccionar el botón amarillo “INGRESAR”, completa tu correo electrónico y contraseña." },
    { question: "¿Qué pasa si olvido mi contraseña?", answer: "Si olvidaste tu contraseña, debes dirigirte a la esquina superior derecha y seleccionar el botón amarillo “INGRESAR”, ahora solo debes presionar el enlace “¿Olvidaste tu contraseña” ?, escribir tu correo electrónico y seguir las instrucciones." },
    { question: "¿En qué consiste una membresía T’AKAY?", answer: "Al ingresar a nuestra página puedes observar que tenemos dos precios por cada producto, un precio normal (valor de mercado) y un precio membresía, el cuál es significativamente menor. Al obtener una membresía T’AKAY podrás comprar a precio preferencial, teniendo un gran ahorro con relación al precio normal." },
    { question: "¿Qué información está disponible en mi cuenta y cómo puedo acceder a ella?", answer: `Una vez en tu cuenta, puedes ver tu información apretando sobre el <strong>botón negro de la esquina superior derecha</strong> donde aparece tu nombre, luego presiona sobre <strong>“Mi cuenta”</strong> y ahí podrás ver tus:<br /><br />
    • <strong>Datos personales</strong><br />
    • <strong>Pedidos y el detalle de cada uno</strong><br />
    • <strong>Direcciones</strong><br />
    • <strong>Saldo de tu membresía</strong><br />
    • <strong>Saldos</strong>` },
    { question: "¿Cómo modifico los datos de mi cuenta?", answer: `Para modificar tus datos debes iniciar sesión y dirigirte a “Mi cuenta” al presionar sobre tu nombre que está de color negro en la esquina superior derecha donde puedes hacer cambios en los siguientes datos: <br /><br />
    <strong> • Datos Personales:</strong> Puedes modificar todos los datos exceptuando tu rut y luego presionar sobre el botón “Guardar Cambios”.<br /><br />
    <strong> • Contraseña:</strong> En la misma pestaña de “Datos personales”, puedes apretar sobre el botón “Cambiar Contraseña”, ingresar tu nueva contraseña y guardar los cambios.<br /><br />
    <strong> • Direcciones:</strong> Presiona sobre el botón “editar”, modifica los datos solicitados y guarda los cambios.` },
  ],
  "TIPOS DE PRODUCTOS, MEMBRESÍA Y PEDIDOS": [
    { question: "¿Qué significan los productos con etiqueta verde?", answer: `En T’AKAY contamos con productos con etiqueta verde, los cuales pueden ser: <br /><br />
    <strong>• Agroecológico: </strong>Productos con un enfoque agrícola que integra prácticas sostenibles y ecológicas para producir alimentos de manera que respete el medio ambiente y promueva la biodiversidad.<br /><br />
    <strong>• Artesanal: </strong>Productos elaborados de manera manual o semi-industrial, utilizando métodos tradicionales y materias primas de calidad, lo que les otorga características únicas de sabor y textura.<br /><br />
    <strong>• Campo: </strong>Productos cosechados el mismo día de su despacho, provenientes de productores locales, garantizando alta calidad y frescura gracias a su proximidad y rápido proceso de recolección. Cultivo tradicional.<br /><br />
    <strong>• Hidropónico: </strong>Productos con un método de cultivo que utiliza soluciones nutritivas en agua para cultivar plantas sin suelo, permitiendo un crecimiento eficiente y controlado en entornos diversos. <br /><br />
    <strong>• Orgánico: </strong>Productos agrícolas cultivados sin pesticidas, fertilizantes sintéticos ni organismos genéticamente modificados (GMO), promoviendo prácticas naturales y sostenibles.<br /><br />
    <strong>• Biodinámico: </strong>Considera la granja cómo un organismo vivo, promoviendo la diversidad y el uso de preparados naturales. Además, se basa en ciclos astronómicos para guiar las prácticas agrícolas, buscando un equilibrio entre el cultivo y el medio ambiente.<br /><br />
    Todos los productos con etiqueta verde, tienen un despacho exclusivo los días sábados, debido a que son cosechados y recolectados durante esa misma mañana.` }
  ],
  "Despacho y coberturas": [
    { question: "¿Cuáles son las zonas de cobertura?", answer: "Actualmente entregamos en Santiago y alrededores." },
    { question: "¿Cuánto tarda el despacho?", answer: "El despacho puede tardar entre 24 y 48 horas." }
  ],
  "Dudas del pedido": [
    { question: "¿Cómo puedo modificar mi pedido?", answer: "Puedes modificar tu pedido en la sección 'Mis pedidos' antes del despacho." },
    { question: "¿Cómo cancelo un pedido?", answer: "Si necesitas cancelar tu pedido, contáctanos antes del despacho." }
  ],
  "Problemas con el pedido": [
    { question: "¿Qué hacer si mi pedido llega incompleto?", answer: "Revisa tu boleta y si hay un error, avísanos para corregirlo." },
    { question: "¿Cómo reporto un problema?", answer: "Utiliza la sección de contacto en nuestra web o envíanos un correo." }
  ]
};

export const FAQPage = () => {
    const [selectedCategory, setSelectedCategory] = useState("Todas las preguntas");
  
    return (
      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <List>
              {categories.map((category, index) => (
                <ListItem key={index} disablePadding>
                  <ListItemButton onClick={() => setSelectedCategory(category.name)} selected={selectedCategory === category.name}>
                    <ListItemIcon>{category.icon}</ListItemIcon>
                    <ListItemText primary={category.name} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid item xs={12} md={9}>
            <Typography variant="h5" fontWeight="bold" gutterBottom>
              <HelpOutlineIcon sx={{ mr: 1 }} /> {selectedCategory}
            </Typography>
            {questionsData[selectedCategory]?.map((item, index) => (
              <Accordion key={index}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>{item.question}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography dangerouslySetInnerHTML={{ __html: item.answer }} />
                </AccordionDetails>
              </Accordion>
            ))}
          </Grid>
        </Grid>
      </Container>
    );
  };