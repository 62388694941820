import React, { createContext, useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode} from "jwt-decode";
import { CartContext } from "../context/CartContext";
import { isLogging } from "../client/apiAuth";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(() => localStorage.getItem("user") || null);
  const [userId, setUserId] = useState(() => localStorage.getItem("userId") || null);
  const [balance, setBalance] = useState(() => {
    const savedBalance = localStorage.getItem("balance");
    return savedBalance ? parseFloat(savedBalance) : 0;
  });
  const { cartItems, setCartItems, setFinalPriceSummary } = useContext(CartContext);
  const navigate = useNavigate();

  const fetchBalance = async (id) => {
      try {
        const response = await isLogging(id);
        const newBalance = response.balance;
        //setBalance(newBalance);
        //localStorage.setItem("balance", newBalance);

        adjustCartPrices(newBalance);
      } catch (error) {
        console.error("Error fetching balance", error);
        clearAuthData();
      }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        console.log(decodedToken)
        if (decodedToken.exp > currentTime) {
          setUser(decodedToken.username);
          setUserId(decodedToken.userId);
          localStorage.setItem("userId", decodedToken.userId)
          fetchBalance(decodedToken.userId);
        } else {
          clearAuthData();
        }
      } catch (error) {
        console.error("Error decoding token", error);
        clearAuthData();
      }
    }else{
      clearAuthData();
    }
  }, []);
  

  const login = (userData) => {
    const user = userData.username;
    setUser(user);
    localStorage.setItem("token", userData.token);
    const decodedToken = jwtDecode(userData.token);
    const currentTime = Date.now() / 1000;

    if (decodedToken.exp > currentTime) {
      setUser(decodedToken.username);
      setUserId(decodedToken.userId);
      const newBalance = decodedToken.totalBalance;
      setBalance(newBalance);
      localStorage.setItem("balance", newBalance);
  
      adjustCartPrices(newBalance);
    }
  };

  const adjustCartPrices = (newBalance) => {
    let updatedBalance = newBalance;
  
    const updatedCartItems = cartItems.map((item) => {
      if (!item.isMembership && updatedBalance >= parseInt(item.price_membership)) {
        console.log("Cambiar a precio de membresía");
  
        const membershipPrice = parseInt(item.price_membership);
        const totalItemCost = membershipPrice * item.quantity;
  
        updatedBalance -= totalItemCost;
  
        return {
          ...item,
          price: membershipPrice, 
          isMembership: true,
          id: item.id.replace("_normal", ""),
        };
      } else {
        if(item.isMembership && updatedBalance>0){
          const membershipPrice = parseInt(item.price_membership);
          const totalItemCost = membershipPrice * item.quantity;
    
          updatedBalance -= totalItemCost;
        }
        return item;
      }
    });
  
    setBalance(updatedBalance);
    setCartItems(updatedCartItems);
  };
  

  const logout = () => {
    clearAuthData();
    setCartItems([]);
    navigate("/");
  };

  const clearAuthData = () => {
    setUser(null);
    setUserId(null);
    setBalance(0);
  
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("balance");
    localStorage.removeItem("membershipId");
    localStorage.removeItem("cartData");
    localStorage.removeItem("userId");
    localStorage.removeItem("tempCartTotal");
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, userId, balance, setBalance, adjustCartPrices }}>
      {children}
    </AuthContext.Provider>
  );
};
