import React, { useState, useEffect, useContext } from 'react';
import { Typography, Card, CardContent, Box, Button, CircularProgress, Alert } from '@mui/material';
import { createSalesWithTransfer } from '../../client/apiSalesOrder';
import { CartContext } from "../../context/CartContext";
import { useNavigate } from 'react-router-dom';

export const TransferPaymentInfo = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [id, setId] = useState('');
  const [timer, setTimer] = useState(15); // Temporizador de 30 segundos
  const { clearCart } = useContext(CartContext);
  const navigate = useNavigate();

  useEffect(() => {
    const initiateTransfer = async () => {
      try {
        const sales = localStorage.getItem("cartData");
        const response = await createSalesWithTransfer(sales);
        setId(response.data.id);
        if (response?.success) {
          setSuccessMessage(response.message);

          localStorage.removeItem("cartData");
          localStorage.removeItem("cartItems");
          clearCart();

          // Comienza la cuenta regresiva
          setTimer(15);
        } else {
          throw new Error(response.message || 'Error en la confirmación del pedido');
        }
      } catch (error) {
        setError(error.message || 'Hubo un problema al confirmar tu pedido');
      } finally {
        setLoading(false);
      }
    };

    initiateTransfer();
  }, []);

  useEffect(() => {
    if (timer > 0) {
      const countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      return () => clearInterval(countdown);
    } else {
      navigate('/', { replace: true });
    }
  }, [timer, navigate]);

  const handleRedirectNow = () => {
    navigate('/', { replace: true });
  };

  const handleContact = () => {
    window.open('https://wa.me/973765050', '_blank');
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ maxWidth: 600, margin: 'auto', mt: 4 }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  if (successMessage) {
    return (
      <Card sx={{ maxWidth: 600, margin: 'auto', mt: 4 }}>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            <strong>#{id}</strong> - <strong>{successMessage}</strong>
          </Typography>
          <Typography variant="body1" gutterBottom>
            Hemos enviado un correo con los datos de transferencia bancaria. Por favor confirma enviando el comprobante para proceder con la preparación de tu pedido.
            Si tienes alguna duda, contáctanos por WhatsApp y te responderemos a la brevedad.
          </Typography>

          {/* Mensaje de redirección con estilo */}
          <Typography
            variant="body2"
            align="center"
            sx={{
              mt: 2,
              backgroundColor: 'red',
              color: 'white',
              fontWeight: 'bold',
              padding: '8px',
              borderRadius: '4px',
            }}
          >
            Serás redirigido a la página principal en {timer} segundos.
          </Typography>

          {/* Botones de WhatsApp y Redirección */}
          <Box display="flex" justifyContent="center" gap={2} mt={3}>
            <Button variant="contained" color="success" onClick={handleContact}>
              Contactar por WhatsApp
            </Button>
            <Button variant="outlined" color="primary" onClick={handleRedirectNow}>
              Ir al inicio
            </Button>
          </Box>
        </CardContent>
      </Card>
    );
  }

  return null;
};

export default TransferPaymentInfo;
