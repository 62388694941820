import React, { useState, useEffect } from "react";
import { Box, Typography, Card, CardContent, Grid, Checkbox, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { getPaidSales } from "../../client/apiSalesOrder";
import { createConsolidated } from "../../client/apiConsolidated";

export const PaidOrders = () => {
  const [orders, setOrders] = useState([]);
  const [selectedOrderIds, setSelectedOrderIds] = useState([]);
  const [openDialog, setOpenDialog] = useState(false); // Estado para controlar el diálogo
  const [ordersToRemove, setOrdersToRemove] = useState([]); // Estado para almacenar órdenes a eliminar

  const fetchOrders = async () => {
    try {
      const response = await getPaidSales();
      setOrders(response);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  const handleSelectOrder = (orderId) => {
    setSelectedOrderIds((prevSelected) =>
      prevSelected.includes(orderId)
        ? prevSelected.filter((id) => id !== orderId)
        : [...prevSelected, orderId]
    );
  };

  const handleSubmitSelectedOrders = async () => {
    try {
      const response = await createConsolidated(selectedOrderIds);

      if (response && !response.error) {
        // Guardar las órdenes a eliminar y mostrar el diálogo
        setOrdersToRemove(selectedOrderIds);
        setOpenDialog(true); // Mostrar diálogo de confirmación
      }
    } catch (error) {
      console.error("Error creating consolidated orders:", error);
    }
  };

  const handleCloseDialog = () => {
    // Eliminar las órdenes seleccionadas del estado
    setOrders((prevOrders) =>
      prevOrders.filter((order) => !ordersToRemove.includes(order.id))
    );
    setSelectedOrderIds([]); // Limpiar la selección
    setOpenDialog(false); // Cerrar el diálogo
  };

  return (
    <Box sx={{ width: "100%", maxWidth: 800, mx: "auto", p: 2 }}>
      <Typography variant="h4" align="center" sx={{ mb: 4 }}>
        Listado de Órdenes
      </Typography>
      <Grid container spacing={2}>
        {orders.map((order) => (
          <Grid item xs={12} key={order.id}>
            <Card
              sx={{
                border: "1px solid #4caf50",
                boxShadow: "0 3px 5px rgba(0, 0, 0, 0.2)",
                "&:hover": {
                  boxShadow: "0 6px 10px rgba(0, 0, 0, 0.3)",
                },
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box sx={{ display: "flex", gap: 2 }}>
                  <Typography variant="body2"><strong>Orden ID:</strong> {order.id}</Typography>
                  <Typography variant="body2"><strong>Cliente:</strong> {order.client.name} {order.client.name}</Typography>
                  <Typography variant="body2"><strong>Fecha de Compra:</strong> {new Date(order.created_at).toLocaleDateString()}</Typography>
                  <Typography variant="body2"><strong>Fecha de Envío:</strong> {new Date(order.shipping_date + 'T00:00:00').toLocaleDateString()}</Typography>
                </Box>
                <Checkbox
                  checked={selectedOrderIds.includes(order.id)}
                  onChange={() => handleSelectOrder(order.id)}
                  color="primary"
                />
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        sx={{ mt: 4 }}
        onClick={handleSubmitSelectedOrders}
        disabled={selectedOrderIds.length === 0}
      >
        Enviar Órdenes Seleccionadas
      </Button>

      {/* Diálogo de confirmación */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
      >
        <DialogTitle>Órdenes Consolidadas</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Las órdenes seleccionadas han sido consolidadas correctamente.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
