import axios from 'axios';
import { API_URL } from '../const/api';

const API = `${API_URL}/sales`; // Cambia la URL según tu configuración

export const createSalesWithTransfer = async (sales) => {
  try {
    const response = await axios.post(API, { sales }, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error en la solicitud:', error);
    throw error;
  }
};
export const getSalesOrderClientById = async (id) => {
  try {

    const response = await axios.get( API+'/client/'+id, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error en la solicitud:', error);
    throw error;
  }
};

export const updateSalesOrder = async (id, status) => {
  try {

    const response = await axios.put(API, 
      {
        id,
        status
      },
      {
        headers: {
          'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error en la solicitud:', error);
    throw error;
  }
};

export const getPaidSales = async () => {
  try {

    const response = await axios.get( API+'/paid-orders', {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error en la solicitud:', error);
    throw error;
  }
};

export const getSalesOrderById = async (id) => {
  try {

    const response = await axios.get( API+'/'+id, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error en la solicitud:', error);
    throw error;
  }
};

export const updateSalesOrderDetailStatus = async (id, status) => {
  try {

    const response = await axios.put(API, 
      {
        id,
        status
      },
      {
        headers: {
          'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error en la solicitud:', error);
    throw error;
  }
};

export const createPreSales = async (sales) => {
  try {
    const response = await axios.post(API+'/pre-save', { sales }, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error en la solicitud:', error);
    throw error;
  }
};